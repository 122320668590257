import React, { useEffect } from 'react';

export const PayPalInfo = ({ totalValue, paypalClientID, paypalCountry }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const existingScript = document.getElementById('paypalScript');

      // Add PayPal information script
      if (!existingScript) {
        const paypalScript = document.createElement('script');
        paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientID}&currency=EUR&components=messages`;
        paypalScript.async = true;
        paypalScript.id = 'paypalScript';
        document.body.appendChild(paypalScript);
      }
    }
  }, []);

  return (
    <div
      data-pp-message
      data-pp-placement="product"
      data-pp-amount={totalValue || ''}
      data-pp-countryCode={paypalCountry}
      data-pp-buyerCountry={paypalCountry}
    />
  );
};
