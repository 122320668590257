import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, PinterestShareButton, EmailShareButton } from 'react-share';
import { I18n, T } from '@deity/falcon-i18n';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import Container from '../../layout/Container/Container';
import { Button } from '../../ui/Button/Button';
import loadable from '../../../../../components/loadable';
import styles from './SocialSharing.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

const SocialSharing = withStoreConfiguration(({ storeConfiguration, image, productTitle }) => {
  const enableModule = 'asam_socialsharing' in storeConfiguration;
  const enableFb = enableModule ? parseInt(storeConfiguration?.asam_socialsharing?.facebook?.enabled, 10) : false;
  const enablePin = enableModule ? parseInt(storeConfiguration?.asam_socialsharing?.pinterest?.enabled, 10) : false;
  const enableMail = enableModule ? parseInt(storeConfiguration?.asam_socialsharing?.mail?.enabled, 10) : false;
  const enableRecruit = enableModule ? parseInt(storeConfiguration?.asam_socialsharing?.recruit?.enabled, 10) : false;
  const allDisabled = !enableFb && !enablePin && !enableMail && !enableRecruit;
  const recruitUrl = enableModule ? storeConfiguration?.asam_socialsharing?.recruit?.url : null;
  const title = enableModule ? storeConfiguration?.asam_socialsharing?.general?.text : null;
  const subject = enableModule ? storeConfiguration.asam_socialsharing?.mail?.subject : null;
  const checkSubject = subject === null ? '' : subject;
  const [showPinModal, setShowPinModal] = useState(false);
  const [showFbModal, setShowFbModal] = useState(false);
  const [showRecruitOverlay, setShowRecruitOverlay] = useState(false);

  const pinModal = (
    <React.Fragment>
      <div className={[styles.SocialSharingPopUp, 'title'].join(' ')}>
        <T id="socialShare.title" />
      </div>
      <p>
        <T id="socialShare.description" />
      </p>
      <PinterestShareButton url={typeof window !== 'undefined' ? window.location.href : '/'} media={image}>
        <Button>
          <T id="socialShare.confirm" />
        </Button>
      </PinterestShareButton>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span onClick={() => setShowPinModal(false)} className="Link LinkPurple LinkLarge">
        <T id="socialShare.cancel" />
      </span>
    </React.Fragment>
  );

  const fbModal = (
    <React.Fragment>
      <div className={[styles.SocialSharingPopUp, 'title'].join(' ')}>
        <T id="socialShare.title" />
      </div>
      <p>
        <T id="socialShare.description" />
      </p>
      <FacebookShareButton url={typeof window !== 'undefined' ? window.location.href : '/'}>
        <Button>
          <T id="socialShare.confirm" />
        </Button>
      </FacebookShareButton>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span onClick={() => setShowFbModal(false)} className="Link LinkPurple LinkLarge">
        <T id="socialShare.cancel" />
      </span>
    </React.Fragment>
  );

  return enableModule && !allDisabled ? (
    <I18n>
      {t => (
        <div className={styles.SocialSharingWrapper}>
          <Container>
            <div className={styles.SocialSharing}>
              <div className={styles.SocialSharingContent}>
                {title ? <div className={styles.SocialSharingTitle}>{title}</div> : null}
                <div className={styles.SocialSharingItems}>
                  {enableFb ? (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className={[styles.SocialSharingFacebook, styles.SocialSharingItem, 'icon-facebook'].join(' ')}
                      onClick={() => setShowFbModal(true)}
                    />
                  ) : null}
                  {enablePin ? (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className={[styles.SocialSharingPinterest, styles.SocialSharingItem, 'icon-pinterest'].join(' ')}
                      onClick={() => setShowPinModal(true)}
                    />
                  ) : null}
                  {enableMail ? (
                    <div className={[styles.SocialSharingEmail, styles.SocialSharingItem, 'icon-email'].join(' ')}>
                      <EmailShareButton
                        url={typeof window !== 'undefined' ? window.location.href : '/'}
                        subject={checkSubject}
                        body={productTitle}
                        separator=": "
                      />
                    </div>
                  ) : null}
                  {enableRecruit && recruitUrl ? (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                      className={[styles.SocialSharingRecruit, styles.SocialSharingItem, 'icon-account-profile'].join(
                        ' '
                      )}
                      onClick={() => setShowRecruitOverlay(true)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
          {showRecruitOverlay ? (
            <Modal
              pageContent={t('cms.recruit.link')}
              variant="tertiary"
              scrollableContent
              visible={showRecruitOverlay}
              close={() => {
                setShowRecruitOverlay(false);
              }}
            />
          ) : null}
          {enablePin ? (
            <Modal
              content={pinModal}
              variant="secondary"
              visible={showPinModal}
              close={() => {
                setShowPinModal(false);
              }}
            />
          ) : null}
          {enableFb ? (
            <Modal
              content={fbModal}
              variant="secondary"
              visible={showFbModal}
              close={() => {
                setShowFbModal(false);
              }}
            />
          ) : null}
        </div>
      )}
    </I18n>
  ) : null;
});

export default SocialSharing;

SocialSharing.propTypes = {
  /** Image that will be used when sharing via Pinterest */
  image: PropTypes.string
};
