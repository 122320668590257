import React, { useEffect, useState } from 'react';
import { ProductSlider } from '../Product';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { ProductListBySkusQuery } from '../Odoscope/ProductListBySkusQuery';
import styles from '../../pages/Product/Product.module.scss';
import { ProductRecommendationBySkuQuery } from './ProductRecommendationQuery';

export const ProductRecommendationSlider = withStoreConfiguration(({ title, productSku, storeConfiguration }) => {
  let skus;
  const [showRecommendation, setShowRecommendation] = useState(false);

  const activateAsamRecommendationEngine = () => {
    setShowRecommendation(true);
  };

  useEffect(() => {
    if (
      storeConfiguration?.asam_productrecommendation?.general &&
      parseInt(storeConfiguration.asam_productrecommendation.general.enabled, 10)
    ) {
      try {
        window.addEventListener('product_recommendation_json', activateAsamRecommendationEngine);
      } catch (e) {
        return null;
      }
    }
  }, []);

  if (showRecommendation && typeof window !== 'undefined') {
    return (
      <React.Fragment>
        <ProductRecommendationBySkuQuery variables={{ sku: productSku }}>
          {({ data: { productRecommendationBySku } }) => {
            if (productRecommendationBySku !== undefined) {
              const recommendations = JSON.parse(productRecommendationBySku);

              if (recommendations && typeof recommendations === 'object') {
                // remove the pdp product
                skus = Object.values(recommendations).filter(sku => sku !== productSku);
              }

              if (skus) {
                return (
                  <ProductListBySkusQuery variables={{ skus }}>
                    {({ data }) => {
                      if (!data || !data.productListBySkus) {
                        return null;
                      }

                      const productsList = data.productListBySkus;
                      return productsList?.items?.length ? (
                        <React.Fragment>
                          <div className={`${styles.RecommendedSliderWrapper} AsambeautyReco`}>
                            <ProductSlider
                              title={title}
                              products={productsList.items}
                              autoHeight
                              location="asambeauty_reco"
                              trackingId=""
                              source="ProductRecommendationSlider"
                            />
                          </div>
                        </React.Fragment>
                      ) : null;
                    }}
                  </ProductListBySkusQuery>
                );
              }
            }
            return null;
          }}
        </ProductRecommendationBySkuQuery>
      </React.Fragment>
    );
  }
  return null;
});
