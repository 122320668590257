import { T } from '@deity/falcon-i18n';
import { Image, Link } from '@deity/falcon-ui';
import { Form } from '@deity/falcon-ui-kit';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../ui/Button/Button';
import { FormField } from '../../../ui/Forms/FormField';
import { ProductImage } from '../../Product/ProductImage/ProductImage';
import { SetProductReviewMutation } from '../ReviewsMutation';
import { Stars } from '../Stars/Stars';
import { supportWebP } from '../../../helpers/BrowserSupport/BrowserSupport';
import { ProductReviewTracking } from '../../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './AddReviewForm.module.scss';

const AddReviewForm = withStoreConfiguration(
  ({ storeConfiguration, account, toggle, formId, product, dispatch, t, location }) => {
    const [reviewRate, setReviewRate] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const variantClasses = [];
    const validate = values => {
      const errors = {};
      const { nickname, title, detail } = values;
      if (nickname.trim() === '') {
        errors.nickname = t('formError.required', {
          label: t('addReview.nicknameFieldPlaceholder')
        });
      }
      if (title.trim() === '') {
        errors.title = t('formError.required', {
          label: t('addReview.titleFieldPlaceholder')
        });
      }
      if (detail.trim() === '') {
        errors.detail = t('formError.required', {
          label: t('addReview.detailFieldPlaceholder')
        });
      }
      return errors;
    };

    if (account) {
      variantClasses.push(styles.AccountReview);
    }

    useEffect(() => {
      if (!account) {
        const webpSupport = supportWebP();
        const imageObj =
          product?.children && product?.children?.length ? product?.children[0]?.gallery : product?.gallery;
        const getImageUrl =
          webpSupport && imageObj[0] && imageObj[0]?.webpThumbnail !== null
            ? imageObj[0]?.webpThumbnail
            : imageObj[0]?.thumbnail;

        setImageUrl(getImageUrl);
      }
    }, []);

    return (
      <div className={[styles.Popup, ...variantClasses].join(' ')}>
        {!account ? (
          <div>
            <div className={styles.PopupTitle}>
              <T id="product.addReviewPopupTitle" />
            </div>
            <div className={styles.PopupText}>
              <p className={styles.PopupTextFirst}>
                <T id="product.addReviewPopupTextFirst" />
              </p>
              <p className={styles.PopupTextSecond}>
                <T id="product.addReviewPopupTextSecond" />
              </p>
            </div>
          </div>
        ) : null}
        <div className={account ? styles.AccountReviewProduct : null}>
          {imageUrl ? <Image src={imageUrl} className={styles.PopupProductImage} alt={product.name} /> : null}
          {account ? (
            <a href={`${product.urlKey}.html`} title={product.name}>
              <ProductImage product={product} className={styles.PopupProductImage} width="200" height="200" />
              <span className={styles.AccountReviewCategory}>{product.cosBrand}</span>
              <span className={styles.AccountReviewProductName}>{product.name}</span>
            </a>
          ) : (
            <div className={styles.PopupProductName}>{product.name}</div>
          )}
        </div>
        <div className={account ? styles.AccountReviewForm : styles.PopupForm}>
          <SetProductReviewMutation>
            {(setProductReview, { loading }) => (
              <Formik
                initialValues={{
                  nickname: '',
                  title: '',
                  detail: '',
                  rate: ''
                }}
                onSubmit={({ nickname, title, detail, rate }, { resetForm }) => {
                  setProductReview({
                    variables: {
                      productId: product.id.toString(),
                      nickname,
                      title,
                      detail,
                      rating: rate
                    }
                  }).then(({ data }) => {
                    let message = null;

                    if (data?.setProductReview?.reviewId) {
                      message = {
                        content: t('notifications.successReviewAdd'),
                        type: 'success',
                        animate: false
                      };

                      ProductReviewTracking({ storeConfiguration, product, rate, location });
                    }

                    if (data?.setProductReview?.success === false) {
                      message = {
                        content: t('notifications.errorReviewAdd'),
                        type: 'error',
                        animate: false
                      };
                    }

                    resetForm();
                    setReviewRate('');

                    if (!account) {
                      toggle();
                    }

                    window.scrollBy(0, -10000);

                    if (message) {
                      dispatch({
                        type: 'ADD_TO_STATE',
                        section: 'messages',
                        data: message
                      });
                    }
                  });
                }}
                validate={values => validate(values)}
                render={({ setFieldValue, errors }) => (
                  <div>
                    <Form id={formId} i18nId="addReview">
                      <div className={account ? styles.AccountReviewFormFrame : null}>
                        {account ? (
                          <p className={styles.AccountReviewFormName}>
                            <T id="product.addReviewPopupTextSecond" />
                          </p>
                        ) : null}
                        <div className={account ? styles.AccountReviewStars : styles.PopupStars}>
                          <Stars
                            form
                            size="large"
                            formError={'rate' in errors}
                            averageRate={reviewRate}
                            handleRateChange={rate => {
                              setFieldValue('rate', rate);
                              setReviewRate(rate);
                            }}
                          />
                        </div>
                        <FormField
                          name="nickname"
                          type="text"
                          required
                          autoComplete="off"
                          variant={account ? 'secondary' : null}
                          label={t('addReview.nicknameFieldPlaceholder')}
                          placeholder={t('addReview.nicknameFieldPlaceholder')}
                          showErrorMessage
                        />
                        <FormField
                          name="title"
                          type="text"
                          required
                          autoComplete="off"
                          variant={account ? 'secondary' : null}
                          label={t('addReview.titleFieldPlaceholder')}
                          placeholder={t('addReview.titleFieldPlaceholder')}
                          showErrorMessage
                        />
                        <FormField
                          className={account ? styles.AccountReviewDetail : null}
                          name="detail"
                          textarea
                          required
                          autoComplete="off"
                          variant={account ? 'secondary' : null}
                          label={t('addReview.detailFieldPlaceholder')}
                          placeholder={t('addReview.detailFieldPlaceholder')}
                          showErrorMessage
                        />
                        <FormField
                          name="rate"
                          type="hidden"
                          required
                          autoComplete="off"
                          variant={account ? 'secondary' : null}
                        />
                        {t('domain.storeCode') === 'asambeauty_fra_fr' ||
                        t('domain.storeCode') === 'asambeauty_eur_de' ||
                        t('domain.storeCode') === 'asambeauty_chf_ch' ? (
                          <p className={styles.DisclaimerText}>
                            <T id="addReview.agreement" />{' '}
                            <a href={t('addReview.agreementLink')} target="_blank" rel="noopener noreferrer nofollow">
                              {t('addReview.agreementLinkText')}
                            </a>
                            {'.'}
                          </p>
                        ) : null}
                      </div>
                      <div className={account ? styles.AccountReviewButton : null}>
                        <Button type="submit" loading={loading} variant={account ? 'secondary' : null}>
                          <T id="addReview.submit" />
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              />
            )}
          </SetProductReviewMutation>
        </div>
        {!account ? (
          <div className={styles.PopupCancel}>
            <Link className="Link LinkPurple LinkLarge" onClick={toggle}>
              <T id="product.addReviewPopupCancel" />
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
);

export default AddReviewForm;
