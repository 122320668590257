import React from 'react';
import { T, I18n } from '@deity/falcon-i18n';
import { Toggle } from 'react-powerplug';
import { Button } from '../../../ui/Button/Button';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import loadable from '../../../../../../components/loadable';
import AddReviewForm from './AddReviewForm';
import styles from './AddReview.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

const PopupContent = ({ toggle, formId, product, dispatch, t, on, scrollablePopup }) => {
  const popupContent = <AddReviewForm toggle={toggle} formId={formId} product={product} dispatch={dispatch} t={t} />;
  return <Modal content={popupContent} visible={on} close={toggle} scrollableContent={scrollablePopup} />;
};

export const AddReview = ({ formId, product, scrollablePopup }) => {
  const [, dispatch] = useGlobalStateValue();

  return (
    <I18n>
      {t => (
        <div className={styles.ReviewsAdd}>
          <div className={styles.ReviewsAddHeader}>
            <T id="product.addReviewHeader" />
          </div>
          <div className={styles.ReviewsAddText}>
            <p>
              <T id="product.addReviewTextFirst" />
            </p>
            <p>
              <T id="product.addReviewTextSecond" />
            </p>
          </div>
          <Toggle initial={false}>
            {({ on, toggle }) => (
              <React.Fragment>
                <div className={styles.ReviewsAddButton}>
                  <Button size="small" onClick={toggle}>
                    <T id="product.addReviewButton" />
                  </Button>
                </div>
                <PopupContent
                  toggle={toggle}
                  formId={formId}
                  product={product}
                  dispatch={dispatch}
                  t={t}
                  on={on}
                  scrollablePopup={scrollablePopup}
                />
              </React.Fragment>
            )}
          </Toggle>
        </div>
      )}
    </I18n>
  );
};

AddReview.defaultProps = {
  scrollablePopup: false
};
