import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Locale } from '@deity/falcon-front-kit';
import { InnerHTML } from '@deity/falcon-ui-kit';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import svgSprite from '../../../../../assets/images/sprites/sprites.svg';
import styles from './Benefits.module.scss';

export const Benefits = withStoreConfiguration(({ storeConfiguration, usage }) => (
  <Locale>
    {localeContext => {
      const price = new Intl.NumberFormat(localeContext.locale, {
        style: 'currency',
        currency: localeContext.currency,
        minimumFractionDigits: 0
      }).format(storeConfiguration?.asam_shippinginformation?.general?.free_shipping_amount);
      const minDelivery = storeConfiguration?.asam_shippinginformation?.general?.min_delivery;
      const maxDelivery = storeConfiguration?.asam_shippinginformation?.general?.max_delivery;
      if (usage === 'pdp') {
        const storeView = storeConfiguration?.general?.country?.default;
        return (
          <React.Fragment>
            <I18n>
              {t => (
                <div
                  data-opt="pdpBenefitsBar"
                  className={[styles.BenefitsBar, storeView === 'DE' || storeView === 'CH' ? 'Hide' : null].join(' ')}
                >
                  <div className={styles.BenefitsWrapper}>
                    <div className={styles.BenefitsIcon}>
                      <img src={`${svgSprite}#preFooterSafety`} width="50" height="50" alt={t('prefooter.payment')} />
                      <div className={styles.BenefitsIconText}>{t('prefooter.payment')}</div>
                    </div>
                    {storeView !== 'FR' ? (
                      <div className={styles.BenefitsIcon}>
                        <img
                          src={`${svgSprite}#freeShipping`}
                          width="50"
                          height="50"
                          alt={t('benefits.shippingFrom30Euro', {
                            freeShippingAmount: price
                          })}
                        />
                        <InnerHTML
                          className={styles.BenefitsIconText}
                          html={t('benefits.shippingFrom30Euro', {
                            freeShippingAmount: price
                          })}
                        />
                      </div>
                    ) : (
                      <div className={styles.BenefitsIcon}>
                        <img src={`${svgSprite}#germany`} width="50" height="50" alt={t('benefits.madeInGermany')} />
                        <div className={styles.BenefitsIconText}>{t('benefits.madeInGermany')}</div>
                      </div>
                    )}
                    {storeView !== 'FR' ? (
                      <div className={styles.BenefitsIcon}>
                        <img src={`${svgSprite}#freeRefund`} width="50" height="50" alt={t('benefits.refund30Days')} />
                        <div className={styles.BenefitsIconText}>{t('benefits.refund30Days')}</div>
                      </div>
                    ) : (
                      <div className={styles.BenefitsIcon}>
                        <img
                          src={`${svgSprite}#fastDelivery`}
                          width="50"
                          height="50"
                          alt={t('benefits.fastDelivery', {
                            minDelivery,
                            maxDelivery
                          })}
                        />
                        <div className={styles.BenefitsIconText}>
                          {t('benefits.fastDelivery', {
                            minDelivery,
                            maxDelivery
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </I18n>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <I18n>
            {t => (
              <div className={styles.Benefits}>
                <p className={styles.BenefitsHeader}>{t('benefits.customerBenefits')}</p>
                <div className={styles.BenefitsWrapper}>
                  <div className={styles.BenefitsIcon}>
                    <img
                      src={`${svgSprite}#fastDelivery`}
                      width="50"
                      height="50"
                      alt={t('benefits.fastDelivery', {
                        minDelivery,
                        maxDelivery
                      })}
                    />
                    <div className={styles.BenefitsIconText}>
                      {t('benefits.fastDelivery', {
                        minDelivery,
                        maxDelivery
                      })}
                    </div>
                  </div>
                  <div className={styles.BenefitsIcon}>
                    <img src={`${svgSprite}#freeShipping`} width="50" height="50" alt="Free shipping" />
                    <InnerHTML
                      className={styles.BenefitsIconText}
                      html={t('benefits.shippingFrom30Euro', {
                        freeShippingAmount: price
                      })}
                    />
                  </div>
                  <div className={styles.BenefitsIcon}>
                    <img src={`${svgSprite}#freeRefund`} width="50" height="50" alt={t('benefits.refund30Days')} />
                    <div className={styles.BenefitsIconText}>{t('benefits.refund30Days')}</div>
                  </div>
                </div>
              </div>
            )}
          </I18n>
        </React.Fragment>
      );
    }}
  </Locale>
));
