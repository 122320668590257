import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_PRODUCT_RECOMMENDATION_BY_SKU = gql`
  query ProductRecommendationBySku($sku: String) {
    productRecommendationBySku(sku: $sku)
  }
`;

export class ProductRecommendationBySkuQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT_RECOMMENDATION_BY_SKU
  };
}
