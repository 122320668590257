import React from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import styles from './CmsHeadline.module.scss';

export const CmsHeadline = ({ headline, headlineTag, align, itemProp }) => {
  const headlineAlign = [];
  if (!headline) {
    return null;
  }
  switch (align) {
    case 'left':
      headlineAlign.push(styles.CmsHeadlineLeft);
      break;
    case 'right':
      headlineAlign.push(styles.CmsHeadlineRight);
      break;
    case 'center':
      headlineAlign.push(styles.CmsHeadlineCenter);
      break;
    default:
      break;
  }
  switch (headlineTag) {
    case 'h1':
      return (
        <h1 itemProp={itemProp} className={[styles.CmsHeadline, ...headlineAlign].join(' ')}>
          <InnerHTML html={headline} />
        </h1>
      );
    case 'h2':
      return (
        <h2 itemProp={itemProp} className={[styles.CmsHeadline, ...headlineAlign].join(' ')}>
          <InnerHTML html={headline} />
        </h2>
      );
    case 'h3':
      return (
        <h3 itemProp={itemProp} className={[styles.CmsHeadline, ...headlineAlign].join(' ')}>
          <InnerHTML html={headline} />
        </h3>
      );
    case 'h4':
      return (
        <h4 itemProp={itemProp} className={[styles.CmsHeadline, ...headlineAlign].join(' ')}>
          <InnerHTML html={headline} />
        </h4>
      );
    case 'h5':
      return (
        <h5 itemProp={itemProp} className={[styles.CmsHeadline, ...headlineAlign].join(' ')}>
          <InnerHTML html={headline} />
        </h5>
      );
    case 'span':
      return (
        <span>
          <InnerHTML html={headline} />
        </span>
      );
    case 'p':
      return (
        <p>
          <InnerHTML html={headline} />
        </p>
      );
    default:
      return null;
  }
};
