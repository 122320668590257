import React from 'react';
import styles from './Stars.module.scss';

const MAX_REVIEW_STARS = 5;

export const ViewStars = ({ form, formError, averageRate, showTotalReviews, totalReviews, size }) => {
  const width = (100 * averageRate) / MAX_REVIEW_STARS;

  return (
    <div
      className={[
        styles.ViewStars,
        size === 'large' ? styles.StarsLarge : null,
        form ? styles.StarsForm : null,
        formError ? styles.StarsError : null
      ].join(' ')}
    >
      <div className={styles.StarsContainerView}>
        <span className={styles.StarsPercent} style={{ width: `${width}%` }} />
      </div>
      {showTotalReviews ? <div className={styles.TotalReviews}>{totalReviews}</div> : null}
    </div>
  );
};
