import { Mutation } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const SET_PRODUCT_REVIEW = gql`
  mutation SetProductReview($productId: String!, $nickname: String!, $title: String!, $detail: String!, $rating: Int) {
    setProductReview(productId: $productId, nickname: $nickname, title: $title, detail: $detail, rating: $rating) {
      reviewId
      success
      message
    }
  }
`;

export class SetProductReviewMutation extends Mutation {
  static defaultProps = {
    mutation: SET_PRODUCT_REVIEW,
    refetchQueries: ['UnreviewedProducts']
  };
}
