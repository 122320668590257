import { ProductQuery } from '@deity/falcon-shop-data';
import gql from 'graphql-tag';

export const GET_PRODUCT = gql`
  fragment productBase on Product {
    id
    sku
    ean
    brand
    name
    categoryIds
    description
    isInStock
    type
    volume
    baseprice
    applicationType
    paybackEnabled
    paybackBasePoints
    price {
      regular
      special
      minPrice
      maxPrice
      minTier
      specialPriceFrom
      specialPriceTo
    }
    tierPrices {
      qty
      value
      discount
    }
    currency
    gallery {
      full
      thumbnail
      webpFull
      webpThumbnail
      type
      embedUrl
      videoAutoplay
    }
    seo {
      title
      description
      keywords
    }
    storeUrls {
      storeCode
      url
    }
    stock {
      isInStock
      qty
    }
    rating
    reviewsSummary {
      totalCount
      averageRating
    }
    customAttributes
    labels {
      entityId
      text
      type
      percent
      isSale
      size
      rotated
      transparent
      badge
      images {
        image
      }
    }
    categoryPath

    contentData {
      # property 'id' is not used in frontend, but needed for ssr cache
      id
      sku
      short_name
      short_description
      long_description
      product_slider
      tag_navigation
      description
      directions
      testresults
      ingredients
      canonicalLink
      product_quotes
      upsale_container
      meta_title
      meta_description
      index
      storeUrls {
        storeCode
        url
      }
    }
    customOptions {
      title
      type
      productSku
      optionId
      values {
        title
        price
        priceType
        optionTypeId
      }
    }
    subscriptionOffer {
      enabled
      defaultSet
      price
      strikePrice
      intervals
      defaultInterval
      basePrice
      highlightBestsellerInterval
    }
  }

  query Product($id: ID!) {
    product(id: $id) {
      ...productBase
      recommendations {
        items {
          applicationType
          id
          name
          sku
          price {
            regular
            special
            minPrice
            maxPrice
            minTier
          }
          productImage {
            default
            retina
            defaultWebp
            retinaWebp
          }
          urlPath
          volume
          baseprice
          popularity
          rating
          reviewsSummary {
            totalCount
            averageRating
          }
          isInStock
          shortDescription
          brand
          hasCustomOptions
          type
          options {
            id
            values {
              valueIndex
              label
              isInStock
              swatch {
                type
                value
              }
            }
          }
          labels {
            entityId
            text
            type
            percent
            isSale
            size
            rotated
            transparent
            badge
            images {
              image
            }
          }
        }
      }
      options {
        id
        attributeId
        attributeCode
        label
        productId
        values {
          valueIndex
          label
          isInStock
          swatch {
            type
            value
          }
        }
      }
      children {
        ...productBase
        configurations {
          value
          attributeCode
        }
      }
    }
  }
`;

export class ProductQueryExt extends ProductQuery {
  static defaultProps = {
    ...super.defaultProps,
    query: GET_PRODUCT,
    fetchPolicy: 'cache-first',
    passLoading: true
  };
}
