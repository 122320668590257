import { I18n, T } from '@deity/falcon-i18n';
import React from 'react';
import { Toggle } from 'react-powerplug';
import Container from '../../../layout/Container/Container';
import { Button } from '../../../ui/Button/Button';
import { ReviewsSummary } from '../ReviewsSummary/ReviewsSummary';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';
import styles from './ReviewsList.module.scss';

const getReviewList = (reviews, t, isDesktop, isMobile, isTablet) =>
  reviews.map(item => {
    const date = item.reviewCreatedAt;
    const yearMonth = date.split('-');
    const day = yearMonth[2].split(' ');

    return (
      <div className={styles.ReviewItem} key={`reviewsItem${item.reviewId}`}>
        <Container>
          <div className={styles.ReviewItemLeft}>
            <div className={styles.ReviewItemHeadline}>{item.title}</div>
            {item.ratingVotes && item.ratingVotes[0] && (
              <div className={styles.ReviewItemStars}>
                <ReviewsSummary summary={{ averageRating: item.ratingVotes[0].value }} onlyStars starGray />
              </div>
            )}
            <div className={styles.ReviewItemPerson}>{`${t('product.opinionOf')} ${item.nickname}`}</div>
            <div className={styles.ReviewItemDate}>{`${day[0]}. ${t(`calendar.${yearMonth[1]}`)} ${yearMonth[0]}`}</div>
          </div>
          <div className={styles.ReviewItemRight}>
            <div className={styles.ReviewItemContent}>
              <Toggle initial={false}>
                {({ on, toggle }) => {
                  let charsToShow = null;
                  let hiddenText = null;
                  let visibleText = null;

                  if (isDesktop) {
                    charsToShow = 440;
                  }

                  if (isTablet) {
                    charsToShow = 260;
                  }

                  if (isMobile) {
                    charsToShow = 190;
                  }

                  if (item.detail.length > charsToShow) {
                    hiddenText = item.detail.substring(charsToShow);
                    visibleText = item.detail.substring(0, charsToShow);
                  }

                  return (
                    <React.Fragment>
                      <span>{hiddenText !== null ? visibleText : item.detail}</span>
                      {hiddenText !== null ? (
                        <span className={!on ? styles.ReviewItemContentHidden : null}>{hiddenText}</span>
                      ) : null}

                      {!on && hiddenText !== null ? (
                        <span className={styles.ReviewItemReadMore} onClick={toggle} aria-hidden>
                          <T id="readMore" /> ...
                        </span>
                      ) : null}
                    </React.Fragment>
                  );
                }}
              </Toggle>
            </div>
            {item?.source && item?.source !== 0 ? (
              <p className={styles.ReviewItemSource}>
                {`${item?.source === 1 ? t('reviews.outDatedSkuReview') : t('reviews.reviewFromSimpleProduct')} ${
                  item.sourceName
                }`}
              </p>
            ) : null}
          </div>
        </Container>
      </div>
    );
  });

export const ReviewsList = ({ reviews, loadMore, isLoading, hideLoadMore }) => {
  const { isDesktop, isMobile, isTablet } = useCheckBreakpoints();
  return (
    <I18n>
      {t => (
        <div className={styles.ReviewsList}>
          {getReviewList(reviews, t, isDesktop, isMobile, isTablet)}
          <div className={styles.ReviewsListLoadMore}>
            {!hideLoadMore ? (
              <Button types="lessmore" onClick={loadMore} loading={isLoading}>
                <span className="before-icon-plus">
                  <T id="showMore" />
                </span>
              </Button>
            ) : null}
          </div>
        </div>
      )}
    </I18n>
  );
};
