import { I18n } from '@deity/falcon-i18n';
import React from 'react';
import style from './RedirectInfo.module.scss';

export default () => {
  return (
    <I18n>
      {t => (
        <div>
          <div id="redirect-info" className={style.RedirectTitleInfo}>
            {t('notFound.titleInfo')}
          </div>
        </div>
      )}
    </I18n>
  );
};
