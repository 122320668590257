import React from 'react';
import PropTypes from 'prop-types';
import styles from './OptionsList.module.scss';

export const OptionsList = ({ options, variant, checkboxList }) => {
  const additionalClasses = [];

  if (variant === 'secondary') {
    additionalClasses.push(styles.OptionsListSecondary);
  }

  if (checkboxList) {
    additionalClasses.push(styles.OptionsListCheckboxes);
  }

  const optionsList = options.map((item, index) => (
    <div
      className={styles.OptionsListItem}
      key={`optionItem${index}`} // eslint-disable-line
      onClick={'onAction' in item.props ? item.props.onAction : null}
      aria-hidden
    >
      {item}
    </div>
  ));

  return <div className={[styles.OptionsList, ...additionalClasses].join(' ')}>{optionsList}</div>;
};

OptionsList.defaultProps = {
  variant: 'primary'
};

OptionsList.propTypes = {
  /** Change style variant of option list element */
  variant: PropTypes.oneOf(['primary', 'secondary']),
  /** Array of JSX items elements to display inside list */
  options: PropTypes.arrayOf(PropTypes.node)
};
