import { AddToCartFormProvider } from '@deity/falcon-front-kit';
import { I18n } from '@deity/falcon-i18n';
import { Box } from '@deity/falcon-ui';
import { ErrorSummary, Form } from '@deity/falcon-ui-kit';
import parse from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Breadcrumbs } from '../../elements/Breadcrumbs/Breadcrumbs';
import { OdoscopeSlider } from '../../elements/Odoscope/Slider';
import {
  ActiveProduct,
  AddedToCartPopup,
  CustomOptions,
  Details,
  LastSeenProducts,
  Price,
  ProductSlider,
  Stock,
  BasePrice,
  PriceDate,
  ProductMeta,
  ProductUpsale,
  Variants,
  VariantContext,
  Gallery,
  ProductSubscribe
} from '../../elements/Product';
import { GoogleTagManager4Pageview, CartTracking, ViewItemTracking } from '../../elements/Tracking/GoogleTagManager';
import { AddedToWishlistPopup } from '../../elements/Wishlist/AddedToWishlistPopup/AddedToWishlistPopup';
import { AddToWishlist } from '../../elements/Wishlist/AddToWishlist/AddToWishlist';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import Container from '../../layout/Container/Container';
import { CustomerQuery } from '../../overrides/Customer/CustomerQuery';
import { ProductQueryExt } from '../../overrides/Product/ProductQueryExt';
import { Button } from '../../ui/Button/Button';
import { ChangeItemNumberInput } from '../Cart/CartInputs/CartInputs';
import { StructuredDataProduct } from '../../elements/StructuredData/Product';
import { Benefits } from '../../sections/Customer/Benefits';
import { ProductRecommendationSlider } from '../../elements/ProductRecommendation/Slider';
import { getFreeOfShipping } from '../../helpers/FreeShipping/FreeShipping';
import loadable from '../../../../../components/loadable';
import { ReviewsSummary } from '../../elements/Reviews/ReviewsSummary/ReviewsSummary';
import PayBackInfo from '../../elements/Product/PayBackInfo/PayBackInfo';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import { LastOrderUpdateTracking } from '../../elements/LastOrderUpdateTracking/LastOrderUpdateTracking';
import { PayPalInfo } from '../Checkout/PaymentMethodStep/PaymentMethodItem/helpers/PayPalInfo';
import ElementProductFilter from '../../elements/Cms/Modules/ElementProductFilter';
import { BodyElementAttribute } from '../../helpers/BodyElementAttribute/BodyElementAttribute';
import RedirectInfo from './RedirectInfo';
import styles from './Product.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../elements/Cms/Row'));

const CmsQuote = loadable(() =>
  import(/* webpackChunkName: "product" */ '../../elements/Cms/Modules/Components/CmsQuote')
);
const SocialSharing = loadable(() =>
  import(/* webpackChunkName: "product" */ '../../elements/SocialSharing/SocialSharing')
);
const Reviews = loadable(() => import(/* webpackChunkName: "product" */ '../../elements/Reviews/Reviews'));

const AddSubscription = loadable(() =>
  import(/* webpackChunkName: "subscription" */ '../../elements/AddSubscription/AddSubscription')
);
const SubscriptionCheckout = loadable(() =>
  import(/* webpackChunkName: "subscription" */ '../../elements/SubscriptionCheckout/SubscriptionCheckout')
);
const SubscriptionSuccess = loadable(() =>
  import(/* webpackChunkName: "subscription" */ '../../elements/SubscriptionSuccess/SubscriptionSuccess')
);

const ProductGetNotification = loadable(() =>
  import(/* webpackChunkName: "subscription" */ './ProductGetNotification')
);

const ProductPage = withStoreConfiguration(({ storeConfiguration, id, path, isRedirect = false }) => {
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const [showAddedToWishlist, setShowAddedToWishlist] = useState(false);
  const [showDeliveryOverlay, setShowDeliveryOverlay] = useState(false);
  const [showAgbOverlay, setShowAgbOverlay] = useState(false);
  const [showTagManager, setShowTagManager] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [aboQuantity, setAboQuantity] = useState(1);
  const [priceModificator, setPriceModificator] = useState(0);
  const [aboInterval, setAboInterval] = useState(0);
  const [showAboCheckout, setShowAboCheckout] = useState(false);
  const [showSuccessContent, setShowSuccessContent] = useState(false);
  const [subscription, setSubscription] = useState();
  const storeView = storeConfiguration?.general?.country?.default;
  const maxQtyAllowedInCart = storeConfiguration?.cataloginventory?.item_options?.max_sale_qty;
  const isRedirection = isRedirect || false;
  const searchParameters = (typeof window !== 'undefined' && window.location.search) || '';
  const queryParams = new URLSearchParams(searchParameters);
  const [aboSelect, setAboSelect] = useState(false);
  const [stickCartBtn, setStickCartBtn] = useState(false);
  const [trackChange, setTrackChange] = useState(false);
  const [isCartUpdated, setIsCartUpdated] = useState(false);
  const deliveryException = !!parseInt(storeConfiguration?.asam_shipping?.delivery_exception?.active, 10);
  const deliveryMessage = storeConfiguration?.asam_shipping?.delivery_exception?.message || '';
  const deliveryExceptionSkus = storeConfiguration?.asam_shipping?.delivery_exception?.products_skus?.split(',') || [];

  const { isMobile } = useCheckBreakpoints();

  const stickCartBtnHandler = () => {
    if (typeof window !== 'undefined') {
      const el = document.getElementById('add-to-cart');
      if (el) {
        if (el.getBoundingClientRect().top + 50 < 0) {
          if (!stickCartBtn) {
            setStickCartBtn(true);
          }
        } else {
          setStickCartBtn(false);
        }
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', stickCartBtnHandler);

    BodyElementAttribute({
      key: 'data-page-type',
      value: 'product',
      action: 'set'
    });
    return () => {
      window.removeEventListener('scroll', stickCartBtnHandler);
      BodyElementAttribute({
        key: 'data-page-type',
        action: 'remove'
      });
    };
  }, []);

  return (
    <ProductQueryExt variables={{ id }}>
      {({ data: { product }, loading }) => {
        if (loading) {
          if (
            typeof window !== 'undefined' &&
            (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
              navigator.userAgent.toLowerCase().indexOf('fxios') > -1 ||
              navigator.userAgent.toLowerCase().indexOf('edgios') > -1)
          ) {
            window.scrollTo({
              top: 0
            });
          }
          return (
            <Container>
              <div className="PageLoaderContainer">
                <div className={['PageLoader', 'pdpLoaderDesktop', 'HideMobile'].join(' ')} />
                <div className={['PageLoader', 'pdpLoaderMobile', 'ShowMobile'].join(' ')} />
              </div>
            </Container>
          );
        }

        return (
          <ActiveProduct
            product={product}
            storeConfiguration={storeConfiguration}
            trackChange={trackChange}
            setTrackChange={setTrackChange}
          >
            {({ activeProduct, selectedOptions, selectActiveProduct, state }) => {
              const cosBrandHtml = product.brand ? <div className={styles.ProductBrand}>{product.brand}</div> : null;
              const { sku, contentData } = activeProduct;
              const customAttributes = product.customAttributes ? JSON.parse(product.customAttributes) : {};
              const deliveryDelayed = deliveryExceptionSkus?.includes(sku);
              const dataLayerData = {
                page_type: 'product',
                page_title: activeProduct?.contentData?.meta_title,
                currency: storeConfiguration?.currency?.options?.default,
                product: {
                  name: activeProduct.name, // e.g. 'Lipstick red ...'
                  brand: `${activeProduct.brand} - ${customAttributes?.cosmeticlineValue}`, // e.g. 'M. Asam - Magic Finish'
                  category: activeProduct.categoryPath, // e.g. 'M. Asam/Geschenke/Limitierte Editionen'
                  price: activeProduct.price?.special || product.price?.regular, // e.g. '24.75'
                  sku: activeProduct.sku, // e.g. '41250'
                  imageURL: activeProduct?.gallery[0]?.full
                }
              };
              const productRecommendations =
                product.recommendations.items.filter(item => item.sku !== product.sku) || [];
              const freeOfShipping = getFreeOfShipping(storeConfiguration, priceModificator, product);
              const paybackActive =
                storeConfiguration?.payback?.general?.enabled &&
                parseInt(storeConfiguration.payback.general.enabled, 10);

              // Subscriptions vars below
              const aboProduct = activeProduct?.subscriptionOffer?.enabled; // check if product has subscription active
              const aboProductPrice = activeProduct?.subscriptionOffer?.price;
              const aboStrikePrice = activeProduct?.subscriptionOffer?.strikePrice;
              const aboBasePrice = activeProduct?.subscriptionOffer?.basePrice;
              const aboTabEnabled = activeProduct?.subscriptionOffer?.defaultSet || false;
              setAboSelect(aboTabEnabled);
              const paramAboQuantity =
                parseInt(queryParams.get('aboQuantity'), 10) > 0 && parseInt(queryParams.get('aboQuantity'), 10) < 4
                  ? parseInt(queryParams.get('aboQuantity'), 10)
                  : null;
              if (paramAboQuantity) {
                setAboQuantity(paramAboQuantity);
                setShowAboCheckout(true);
              }

              const checkoutContent = showSuccessContent ? (
                <SubscriptionSuccess product={activeProduct} interval={aboInterval} subscription={subscription} />
              ) : (
                <SubscriptionCheckout
                  product={activeProduct}
                  interval={aboInterval}
                  quantity={aboQuantity}
                  setShowSuccessContent={setShowSuccessContent}
                  setSubscription={setSubscription}
                />
              );

              return (
                <I18n>
                  {t => (
                    <>
                      {contentData && state?.filters?.length ? (
                        <ProductMeta contentData={contentData} product={activeProduct} path={path} />
                      ) : (
                        <ProductMeta contentData={product.contentData} product={product} path={path} />
                      )}
                      <div>
                        <Container>
                          {isRedirection ? <RedirectInfo /> : null}

                          <div className="HideMobile">
                            <Breadcrumbs breadcrumbs={[{ name: product.name, urlPath: path }]} />
                          </div>

                          <div className={styles.Product}>
                            <div className={styles.ProductTitleWithSummary}>
                              <h1 className={styles.ProductName}>
                                {/* eslint-disable-next-line */}
                                      {cosBrandHtml}{' '}{product.name}
                              </h1>
                              <ReviewsSummary summary={product.reviewsSummary} scrollToForm />
                              <div className="Divider DividerHorizontal" />
                            </div>

                            <div className={styles.ProductGallery}>
                              <div className={styles.ProductGalleryWrapper}>
                                <Gallery
                                  items={activeProduct.gallery}
                                  alt={`${product.brand} ${activeProduct.name}`}
                                  labels={activeProduct.labels}
                                />
                              </div>
                            </div>
                            <div className={styles.ProductInfo}>
                              {contentData && contentData.description ? (
                                <div className={styles.ProductShortDescription}>
                                  {parse(contentData.description, {
                                    replace: node => {
                                      if ('name' in node && node.name === 'li') {
                                        return <li className="before-icon-check">{domToReact(node.children)}</li>;
                                      }
                                    }
                                  })}
                                  <Benefits usage="pdp" />
                                </div>
                              ) : null}
                              <div className="Divider DividerHorizontal" />
                              {activeProduct !== product ? (
                                <>
                                  <Variants
                                    options={product.options}
                                    context={VariantContext.pdp}
                                    activeProduct={activeProduct}
                                    selectedOptions={selectedOptions}
                                    selectVariant={(option, value) => selectActiveProduct(option, value)}
                                    setTrackChange={setTrackChange}
                                  />
                                  {!aboProduct ? <div className="Divider DividerHorizontal" /> : null}
                                </>
                              ) : null}
                              {aboProduct ? (
                                <ProductSubscribe
                                  product={activeProduct}
                                  setAboSelect={setAboSelect}
                                  aboInterval={aboInterval}
                                  setAboInterval={setAboInterval}
                                  tR={t}
                                />
                              ) : null}
                              {aboProduct && aboSelect && aboInterval ? (
                                <div className={styles.ProductPriceInfoWrapper}>
                                  <div className={styles.ProductPriceInfo}>
                                    <div className={styles.ProductPrice}>
                                      <Price
                                        value={aboStrikePrice || activeProduct?.price?.regular}
                                        specialPrice={aboProductPrice}
                                        hideUVP={aboStrikePrice}
                                      />
                                    </div>

                                    <div className={styles.ProductContent}>
                                      <div className={styles.ProductFreeShipping}>
                                        <div className={styles.ProductFreeShippingBox}>
                                          <div className={styles.ProductFreeTooltip}>{t('product.freeShipping')}</div>
                                          <div className={styles.ProductInclTax}>
                                            ({t('product.inclTax')}
                                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                            <span onClick={() => setShowDeliveryOverlay(true)}>
                                              {t('product.deliveryshipping')}
                                            </span>
                                            {t('product.inclTaxOne')})
                                          </div>
                                        </div>
                                      </div>
                                      <div>Art-Nr.: {activeProduct.sku}</div>
                                      <div>
                                        {product.volume ? `${t('product.content')}: ${product.volume}` : null}
                                        {aboBasePrice !== 'false' ? <BasePrice info={aboBasePrice} /> : null}
                                      </div>
                                    </div>
                                  </div>
                                  {activeProduct.price.regular + priceModificator > 0 ? (
                                    <React.Fragment>
                                      <div className="Divider DividerHorizontal" />
                                      <div className={styles.ProductAddToCart}>
                                        <Form id="add-to-cart" i18nId="product">
                                          {activeProduct.stock.isInStock ? (
                                            <React.Fragment>
                                              <div className={styles.ProductQuantity}>
                                                <ChangeItemNumberInput
                                                  max={maxQtyAllowedInCart}
                                                  id={`product${product.id}`}
                                                  defaultValue={paramAboQuantity || aboQuantity || 1}
                                                  onChange={ev => {
                                                    // setFieldValue('qty', ev.target.value);
                                                    setAboQuantity(ev.target.value);
                                                  }}
                                                />
                                              </div>
                                              <AddSubscription
                                                setShowAboCheckout={setShowAboCheckout}
                                                interval={aboInterval}
                                                quantity={aboQuantity}
                                              />
                                            </React.Fragment>
                                          ) : null}
                                        </Form>
                                      </div>
                                    </React.Fragment>
                                  ) : null}
                                </div>
                              ) : (
                                <AddToCartFormProvider
                                  quantity={1}
                                  product={activeProduct}
                                  onSuccess={() => {
                                    setShowAddedToCart(true);
                                    CartTracking({
                                      storeConfiguration,
                                      product: activeProduct,
                                      action: 'add',
                                      origin: 'product detail page',
                                      quantity
                                    });
                                    setIsCartUpdated(true);
                                  }}
                                >
                                  {({ isSubmitting, status = {}, setFieldValue, values }) => {
                                    return (
                                      <div className={styles.ProductPriceInfoWrapper}>
                                        {activeProduct.customOptions.length ? (
                                          <CustomOptions
                                            options={activeProduct.customOptions}
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            setPriceModificator={setPriceModificator}
                                          />
                                        ) : null}
                                        <div className={styles.ProductPriceInfo}>
                                          <div className={styles.ProductPrice}>
                                            <Price
                                              value={activeProduct.price.regular + priceModificator}
                                              specialPrice={
                                                activeProduct.price.special
                                                  ? activeProduct.price.special + priceModificator
                                                  : null
                                              }
                                            />
                                          </div>
                                          <div className={styles.ProductContent}>
                                            <div className={styles.ProductFreeShipping}>
                                              {freeOfShipping ? (
                                                <div className={styles.ProductFreeShippingBox}>
                                                  <div className={styles.ProductFreeTooltip}>
                                                    {t('product.freeShipping')}
                                                  </div>
                                                  <div className={styles.ProductInclTax}>
                                                    ({t('product.inclTax')}
                                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                                    <span onClick={() => setShowDeliveryOverlay(true)}>
                                                      {t('product.deliveryshipping')}
                                                    </span>
                                                    {t('product.inclTaxOne')})
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className={styles.ProductInclTax}>
                                                  ({t('product.exclShipping')}
                                                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                                  <span onClick={() => setShowDeliveryOverlay(true)}>
                                                    {t('product.deliveryshipping')}
                                                  </span>
                                                  {t('product.exclShippingOne')})
                                                </div>
                                              )}
                                            </div>
                                            <PriceDate
                                              dateFrom={product.price.specialPriceFrom}
                                              dateTo={product.price.specialPriceTo}
                                            />
                                            <div>Art-Nr.: {activeProduct.sku}</div>
                                            <div>
                                              {product.volume ? `${t('product.content')}: ${product.volume}` : null}
                                              {activeProduct.baseprice && activeProduct.baseprice !== 'false' ? (
                                                <BasePrice info={activeProduct.baseprice} />
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                        {paybackActive &&
                                        activeProduct?.paybackEnabled &&
                                        activeProduct?.paybackBasePoints ? (
                                          <PayBackInfo points={activeProduct?.paybackBasePoints} />
                                        ) : null}
                                        {activeProduct.price.regular + priceModificator > 0 ? (
                                          <React.Fragment>
                                            <div className="Divider DividerHorizontal" />
                                            <div className={styles.ProductAddToCart}>
                                              {activeProduct.stock.isInStock ? (
                                                <Form id="add-to-cart" i18nId="product">
                                                  <React.Fragment>
                                                    <div className={styles.ProductQuantity}>
                                                      <ChangeItemNumberInput
                                                        max={maxQtyAllowedInCart}
                                                        id={`product${product.id}`}
                                                        defaultValue={1}
                                                        onChange={ev => {
                                                          setFieldValue('qty', ev.target.value);
                                                          setQuantity(ev.target.value);
                                                        }}
                                                      />
                                                    </div>
                                                    <Button
                                                      type="submit"
                                                      size="large"
                                                      loading={isSubmitting}
                                                      dataInspect="add-to-cart-button"
                                                      classNames={
                                                        isMobile && stickCartBtn ? styles.ProductCartButtonFixed : ''
                                                      }
                                                    >
                                                      {t('product.addToCart')}
                                                    </Button>
                                                  </React.Fragment>
                                                </Form>
                                              ) : (
                                                <>
                                                  <CustomerQuery>
                                                    {({ data: { customer } }) => {
                                                      return (
                                                        <ProductGetNotification
                                                          product={activeProduct}
                                                          customer={customer}
                                                        />
                                                      );
                                                    }}
                                                  </CustomerQuery>
                                                </>
                                              )}

                                              {storeConfiguration.general?.country?.default === 'FR' ? (
                                                <div className={styles.PaypalBox}>
                                                  <PayPalInfo
                                                    totalValue={activeProduct.price.regular + priceModificator}
                                                    paypalClientID={
                                                      storeConfiguration?.asam_layoutconfiguration?.paypal?.id
                                                    }
                                                    paypalCountry={storeConfiguration.general?.country?.default}
                                                  />
                                                </div>
                                              ) : null}
                                              <Box>{status.error ? <ErrorSummary errors={status.error} /> : null}</Box>
                                            </div>
                                          </React.Fragment>
                                        ) : null}
                                      </div>
                                    );
                                  }}
                                </AddToCartFormProvider>
                              )}
                              {activeProduct.customOptions.length === 0 &&
                              activeProduct.price.regular + priceModificator > 0 ? (
                                <div className={styles.ProductAddToWishlist}>
                                  <AddToWishlist
                                    productId={activeProduct.id}
                                    variant="secondary"
                                    onSuccess={() => {
                                      setShowAddedToWishlist(true);
                                    }}
                                    origin="product detail page"
                                    product={activeProduct}
                                  />
                                </div>
                              ) : null}
                              {deliveryException && deliveryDelayed ? (
                                <>
                                  <div className={styles.DeliveryDelayed}>
                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                    <span onClick={() => setShowAgbOverlay(true)} className="Interactive Underline">
                                      {deliveryMessage}
                                    </span>
                                  </div>
                                  {showAgbOverlay ? (
                                    <Modal
                                      pageContent={t('cms.estimatedDelivery.link')}
                                      variant="tertiary"
                                      scrollableContent
                                      visible={showAgbOverlay}
                                      close={() => {
                                        setShowAgbOverlay(false);
                                      }}
                                    />
                                  ) : null}
                                </>
                              ) : (
                                <Stock productType={activeProduct.type} stock={activeProduct.stock} />
                              )}
                            </div>
                          </div>
                        </Container>

                        {contentData?.upsale_container?.length ? (
                          <ProductUpsale content={contentData.upsale_container} />
                        ) : null}

                        {contentData?.product_quotes ? <CmsQuote item={contentData.product_quotes.fields} /> : null}
                        {contentData ? (
                          <Details key={`pdpDetails-${activeProduct.sku}`} content={contentData} t={t} />
                        ) : null}

                        {contentData?.product_slider?.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key,react/jsx-indent
                          <ElementProductFilter key={`product-slider-${index}`} item={item.fields} />
                        ))}

                        {contentData?.tag_navigation
                          ? contentData.tag_navigation.map((item, index) => (
                              // eslint-disable-next-line react/no-array-index-key,react/jsx-indent
                              <Row
                                key={`rowItem-'${index}'`} // eslint-disable-line react/no-array-index-key
                                item={item.fields}
                                contentType={item.sys.contentType.sys.id}
                              />
                            ))
                          : null}
                        {productRecommendations.length ? (
                          <Container>
                            <div className={styles.ProductSliderWrapper}>
                              <ProductSlider
                                title={t('product.cosmeticlineSliderTitle')}
                                products={productRecommendations}
                                location="product_page_slider"
                                source="PDP_productRecommendations"
                              />
                            </div>
                          </Container>
                        ) : null}
                        <div className="HideTablet">
                          <SocialSharing
                            image={
                              activeProduct.gallery && activeProduct.gallery[0] ? activeProduct.gallery[0].full : null
                            }
                            productTitle={contentData && contentData.meta_title}
                          />
                        </div>
                        <Container>
                          {!showAboCheckout ? (
                            <div className={`${styles.RecommendedSliderWrapper} OdoscopeReco`}>
                              <OdoscopeSlider
                                productSku={activeProduct.sku}
                                title={t('product.odoscopeSliderTitle')}
                                location="product_page"
                              />
                            </div>
                          ) : null}
                        </Container>

                        <Container>
                          {!showAboCheckout ? (
                            <ProductRecommendationSlider
                              productSku={activeProduct.sku}
                              title={t('product.odoscopeSliderTitle')}
                              location="product_page"
                            />
                          ) : null}
                        </Container>
                        <Reviews product={product} />
                        {storeView !== 'US' ? (
                          <Container>
                            <CustomerQuery>
                              {({ data: { customer } }) => (
                                <LastSeenProducts currentProductId={product.sku} customer={customer} />
                              )}
                            </CustomerQuery>
                          </Container>
                        ) : null}
                        <div className="ShowTablet">
                          <SocialSharing
                            image={activeProduct?.gallery?.[0]?.full || null}
                            productTitle={contentData?.meta_title}
                          />
                        </div>
                        <AddedToCartPopup on={showAddedToCart} toggle={() => setShowAddedToCart(false)} />
                        {showAddedToWishlist ? (
                          <AddedToWishlistPopup on={showAddedToWishlist} toggle={() => setShowAddedToWishlist(false)} />
                        ) : null}
                        {showAboCheckout ? (
                          <Modal
                            content={checkoutContent}
                            variant={showSuccessContent ? 'aboCheckoutSuccess' : 'aboCheckout'}
                            scrollableContent
                            disableBgClose={!showSuccessContent}
                            visible={showAboCheckout}
                            open={() => {
                              const root = document.getElementById('root');
                              root.classList.add('StopBgScrolling');
                              root.style.height = `${window.innerHeight}px`;
                            }}
                            close={() => {
                              setShowAboCheckout(false);
                              setShowSuccessContent(false);
                              const root = document.getElementById('root');
                              root.classList.remove('StopBgScrolling');
                              root.style.removeProperty('height');
                              if (paramAboQuantity) {
                                if (typeof window !== 'undefined') {
                                  window.history.replaceState(null, null, window.location.pathname);
                                }
                              }
                            }}
                          />
                        ) : null}
                        {showDeliveryOverlay ? (
                          <Modal
                            pageContent={t('cms.deliveryshipping.link')}
                            variant="tertiary"
                            scrollableContent
                            visible={showDeliveryOverlay}
                            close={() => {
                              setShowDeliveryOverlay(false);
                            }}
                          />
                        ) : null}
                        {showTagManager ? (
                          <React.Fragment>
                            <GoogleTagManager4Pageview dataLayerData={dataLayerData} />
                            {ViewItemTracking({ storeConfiguration, product: activeProduct })}
                          </React.Fragment>
                        ) : null}
                        {setShowTagManager(false)}
                        {isCartUpdated && <LastOrderUpdateTracking product={activeProduct} />}
                        <StructuredDataProduct product={product} activeProduct={activeProduct} />
                      </div>
                    </>
                  )}
                </I18n>
              );
            }}
          </ActiveProduct>
        );
      }}
    </ProductQueryExt>
  );
});
ProductPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default ProductPage;
