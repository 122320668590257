import React from 'react';
import Container from '../../../layout/Container/Container';
import { ProductList } from '../../ProductList/ProductList';
import { ProductSlider } from '../../Product';
import styles from './CmsModules.module.scss';
import { CmsRowHeadline } from './Components/CmsRowHeadline';
import { CmsButton } from './Components/CmsButton';
import { CmsHeadline } from './Components/CmsHeadline';

const ElementProductFilter = ({ item, slidesToScroll, slidesToShow, noDots, infinite, columnIndex }) => {
  const productFilter = (
    <Container>
      <CmsRowHeadline item={item} />
      {item.headline && !!item.headline_position ? (
        <CmsHeadline headline={item.headline} headlineTag={item.headline_type} align={item.headline_align} />
      ) : null}
      {item.display === true ? (
        <ProductSlider
          products={item.products}
          slidesToScroll={slidesToScroll}
          slidesToShow={slidesToShow}
          noDots={noDots}
          infinite={infinite}
          columnIndex={columnIndex}
          location={item?.name?.replace(/\s+/g, '_')?.toLowerCase()}
          trackingTitle={item.name}
          source={item?.tracking ? `ProductSlider_${item.tracking?.[2]}` : `ProductSlider_${item?.name?.split(' ')[0]}`}
        />
      ) : (
        <div className={styles.CmsProductList}>
          <ProductList
            products={item.products}
            source={item?.tracking ? `ProductList_${item.tracking?.[2]}` : `ProductList_${item?.name?.split(' ')[0]}`}
          />
        </div>
      )}
      {item.cta && (item.cta_link_reference || item.related_product || item.cta_link) && (
        <div className={styles.CmsProductFilterBtn}>
          <CmsButton
            relativeLink={item.cta_link_reference ? item.cta_link_reference : null}
            absoluteLink={item.cta_link ? item.cta_link : null}
            buttonText={item.cta}
            tracking={item.tracking}
          />
        </div>
      )}
      {item.headline && item.headline_position !== true && (
        <CmsHeadline headline={item.headline} headlineTag={item.headline_type} align={item.headline_align} />
      )}
    </Container>
  );
  return (
    <section data-cms-block="element_product_filter" className={`${item.mobile_only ? 'ShowMobile' : ''}`}>
      {productFilter}
    </section>
  );
};

export default ElementProductFilter;
