import React from 'react';
import { Icon } from '@deity/falcon-ui';
import styles from './Stars.module.scss';

const MAX_REVIEW_STARS = 5;

export const Stars = ({ form, formError, averageRate, showTotalReviews, totalReviews, size, handleRateChange }) => {
  const width = (100 * averageRate) / MAX_REVIEW_STARS;

  return (
    <div
      className={[
        styles.Stars,
        size === 'large' ? styles.StarsLarge : null,
        form ? styles.StarsForm : null,
        formError ? styles.StarsError : null
      ].join(' ')}
    >
      <div className={styles.StarsContainer}>
        <div className={styles.StarsEmpty}>
          {[...Array(MAX_REVIEW_STARS).keys()].map(item => (
            <Icon
              src="starempty"
              onClick={
                form
                  ? () => {
                      handleRateChange(item + 1);
                    }
                  : null
              }
              key={item}
            />
          ))}
        </div>
        <div className={styles.StarsFull} style={{ width: `${width}%` }}>
          {[...Array(MAX_REVIEW_STARS).keys()].map(item => (
            <Icon src="starfull" key={item} />
          ))}
        </div>
      </div>
      {showTotalReviews ? <div className={styles.TotalReviews}>{totalReviews}</div> : null}
    </div>
  );
};

Stars.defaultProps = {
  form: false
};
