import { I18n } from '@deity/falcon-i18n';
import { Link, Text } from '@deity/falcon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './Breadcrumbs.module.scss';

export const Breadcrumbs = withStoreConfiguration(({ storeConfiguration, breadcrumbs, hideFirst, arrowClass }) => {
  const current = storeConfiguration?.web?.secure?.base_url || null;

  const structuredData = () => {
    const data = {
      '@context': 'http://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'asambeauty',
          item: `${current}`
        }
      ]
    };

    breadcrumbs?.map((item, index) => {
      // eslint-disable-next-line no-return-assign
      return item?.urlPath
        ? (data.itemListElement = [
            ...data.itemListElement,
            {
              '@type': 'ListItem',
              position: index + 2,
              name: item?.name,
              item: `${current}${item?.urlPath?.slice(1)}`
            }
          ])
        : null;
    });

    return JSON.stringify(data);
  };

  return (
    <I18n>
      {t => (
        <>
          <Helmet>
            <script type="application/ld+json">{structuredData()}</script>
          </Helmet>

          <ul className={styles.Breadcrumbs}>
            {hideFirst ? null : (
              <li
                key={t('breadcrumbs.homepage')}
                className={[styles.BreadcrumbItem, 'after-icon-chevron-right'].join(' ')}
              >
                <Link as={RouterLink} to="/">
                  {t('breadcrumbs.homepage')}
                </Link>
              </li>
            )}
            {breadcrumbs?.map(breadcrumb => (
              <li key={breadcrumb.urlPath} className={[styles.BreadcrumbItem, 'after-icon-chevron-right'].join(' ')}>
                {breadcrumb.urlPath ? (
                  <Link
                    className={arrowClass ? 'after-icon-chevron-left' : null}
                    as={RouterLink}
                    to={breadcrumb.urlPath}
                  >
                    {breadcrumb.name}
                  </Link>
                ) : (
                  <Text>{breadcrumb.name}</Text>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </I18n>
  );
});

Breadcrumbs.propTypes = {
  /** If true, it will hide "Home" breadcrumb */
  hideFirst: PropTypes.bool,
  /** Array of object that will render as breadcrumb items */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      urlPath: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};
