import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_PRODUCT_REVIEWS = gql`
  query ProductReviews($id: String!, $pageSize: Int, $curPage: Int, $rateOrder: Boolean, $stars: Int) {
    productReviews(id: $id, pageSize: $pageSize, curPage: $curPage, rateOrder: $rateOrder, stars: $stars) {
      reviews {
        entityId
        statusId
        title
        detail
        source
        sourceName
        nickname
        ratingVotes {
          ratingCode
          value
          percent
        }
        reviewId
        reviewCreatedAt
      }
      reviewsSummary {
        totalCount
        averageRating
        averagePerStar {
          one
          two
          three
          four
          five
        }
      }
      pageSize
      currentPage
      filteredCount
    }
  }
`;

const fetchMore = (data, apolloFetchMore) => {
  return apolloFetchMore({
    variables: { curPage: data.productReviews.currentPage + 1, pageSize: data.productReviews.pageSize },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return prev;
      }

      return {
        productReviews: {
          ...prev.productReviews,
          reviews: [...prev.productReviews.reviews, ...fetchMoreResult.productReviews.reviews],
          pageSize: fetchMoreResult.productReviews.pageSize,
          currentPage: fetchMoreResult.productReviews.currentPage
        }
      };
    }
  });
};

export class ReviewsQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT_REVIEWS,
    fetchMore,
    notifyOnNetworkStatusChange: true,
    passLoading: true
  };
}
