import { I18n, T } from '@deity/falcon-i18n';
import { NetworkStatus } from 'apollo-client';
import React, { useState } from 'react';
import { Loader } from '@deity/falcon-ui-kit';
import Container from '../../layout/Container/Container';
import { Multiselect } from '../../ui/Multiselect/Multiselect';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { Dropdown } from '../../ui/Dropdown/Dropdown';
import { OptionsList } from '../../ui/OptionsList/OptionsList';
import { AddReview } from './AddReview/AddReview';
import { ReviewsList } from './ReviewsList/ReviewsList';
import { ViewStars } from './Stars/ViewStars';
import { ReviewsQuery } from './ReviewsQuery';
import styles from './Reviews.module.scss';

const getBar = (name, count, num, highestRateCount, filterReviewByStars, selectStars) => {
  const rateAsPercent = count ? (100 * count) / highestRateCount : 0;
  const selected = filterReviewByStars === num;
  const selectable = count > 0;

  return (
    <div
      onClick={() => selectable && selectStars(selected ? 0 : num)}
      role="button"
      className={[
        styles.ReviewsRateBar,
        selected ? styles.ReviewsRateBarSelected : null,
        selectable ? styles.ReviewsRateBarSelectable : null
      ].join(' ')}
      key={`bar${name}`}
      tabIndex={105 - num}
    >
      <div className={styles.ReviewsRateBarStar}>{num}</div>
      <div className={styles.ReviewsRateBarProgress}>
        <div className={styles.ReviewsRateBarProgressInner} style={{ width: `${rateAsPercent}%` }} />
      </div>
      <div className={styles.ReviewsRateBarCount}>{count}</div>
    </div>
  );
};

const getBars = (ratePerStars, filterReviewByStars, selectStars) => {
  const starNames = ['five', 'four', 'three', 'two', 'one'];
  const highestRateCount = starNames.reduce((prev, current) =>
    ratePerStars[prev] > ratePerStars[current] ? prev : current
  );

  return starNames.map((item, index) =>
    getBar(
      item,
      ratePerStars[item],
      starNames.length - index,
      ratePerStars[highestRateCount],
      filterReviewByStars,
      selectStars
    )
  );
};

const Reviews = ({ product }) => {
  const [orderReviewByRate, setOrderReviewByRate] = useState('rate');
  const [filterReviewByStars, setFilterReviewByStars] = useState(0);
  const [switchFilterReview, setSwitchFilterReview] = useState(false);

  const selectStars = stars => {
    setFilterReviewByStars(stars);
    setSwitchFilterReview(true);
  };

  return (
    <div className={styles.Reviews} id="reviews">
      <ReviewsQuery
        variables={{
          id: product.id,
          pageSize: 5,
          curPage: 1,
          rateOrder: !filterReviewByStars && orderReviewByRate === 'rate',
          stars: filterReviewByStars
        }}
      >
        {({ data: { productReviews }, fetchMore, networkStatus, loading }) => {
          if (!productReviews) {
            return null;
          }
          if (!loading) {
            setSwitchFilterReview(false);
          }
          return (
            <React.Fragment>
              <Container flex>
                <div className={styles.ReviewsColumn}>
                  <div className={styles.ReviewsTitle}>
                    <T id="product.productReviews" />
                  </div>
                  <div className={styles.ReviewsRate}>
                    <ViewStars
                      showTotalReviews
                      totalReviews={productReviews.reviewsSummary.totalCount}
                      averageRate={productReviews.reviewsSummary.averageRating}
                    />
                    <div className={styles.ReviewsRateTotalSummary}>
                      <T id="product.reviewsFromPrefix" /> {productReviews.reviewsSummary.averageRating}{' '}
                      <T id="product.reviewsFrom" /> 5 <T id="product.reviewsStars" />
                    </div>
                  </div>
                  <div className={styles.ReviewsRateBars}>
                    {getBars(productReviews.reviewsSummary.averagePerStar, filterReviewByStars, selectStars)}
                  </div>
                  {!filterReviewByStars && productReviews.reviewsSummary.totalCount ? (
                    <I18n>
                      {t => {
                        const sorterOptions = [
                          { label: t('product.sortByBest'), value: 'rate' },
                          { label: t('product.sortByNewest'), value: 'newest' }
                        ];

                        const checkboxOptions = sorterOptions.map((item, index) => (
                          <Checkbox
                            value={item.value}
                            label={item.label}
                            name="sorting"
                            key={item.value}
                            id={`item${index}`}
                            renderAsRadio
                            checked={orderReviewByRate === item.value}
                            onChange={e => setOrderReviewByRate(e.target.value)}
                          />
                        ));

                        const optionsList = <OptionsList options={checkboxOptions} />;

                        return (
                          <div className={styles.ReviewsSorter}>
                            <div className="HideTablet">
                              <Dropdown
                                options={sorterOptions}
                                preSelected={orderReviewByRate}
                                size="small"
                                title={t('product.sortBy')}
                                onChange={val => {
                                  setOrderReviewByRate(val);
                                }}
                              />
                            </div>
                            <div className="ShowTablet">
                              <Multiselect
                                headers={[`${t('product.sortByCapitalized')}`]}
                                contents={[optionsList]}
                                removePaddingOnMobile
                                iconVariant="secondary"
                              />
                            </div>
                          </div>
                        );
                      }}
                    </I18n>
                  ) : null}
                </div>
                <div className={[styles.ReviewsColumn, 'HideTablet'].join(' ')}>
                  <AddReview formId="addReview" product={product} scrollablePopup />
                </div>
              </Container>
              {/* Legal Notice */}
              <Container>
                <p className={styles.ReviewsNotice}>
                  <T id="product.reviewsNotice" />
                </p>
              </Container>
              {switchFilterReview ? (
                <Loader />
              ) : (
                <ReviewsList
                  reviews={productReviews.reviews || []}
                  loadMore={fetchMore}
                  isLoading={networkStatus === NetworkStatus.fetchMore}
                  hideLoadMore={
                    productReviews.reviews.length === productReviews.filteredCount ||
                    productReviews.reviews.length === productReviews.reviewsSummary.totalCount
                  }
                />
              )}
            </React.Fragment>
          );
        }}
      </ReviewsQuery>
      <Container>
        <div className="ShowTablet ClearFloat">
          <AddReview formId="addReviewBottom" product={product} scrollablePopup />
        </div>
      </Container>
    </div>
  );
};

export default Reviews;
