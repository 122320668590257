import React from 'react';
import { Helmet } from 'react-helmet-async';
import sanitizeHtml from 'sanitize-html';
import { BackendConfigQuery } from '@deity/falcon-shop-data';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';

export const StructuredDataProduct = withStoreConfiguration(({ product, storeConfiguration, activeProduct }) => {
  if (parseInt(storeConfiguration?.asam_structureddata?.general?.active, 10) && product) {
    const structuredDataSingle = activeStore => {
      const customAttributes = JSON.parse(product.customAttributes);
      const availability = product.stock?.isInStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';
      const currentUrl = product.storeUrls?.find(item => item.storeCode === activeStore)?.url || '';

      if (customAttributes && product) {
        const data = {
          '@context': 'http://schema.org/',
          '@type': 'Product',
          name: `${product.name}`,
          image:
            (product.type === 'configurable' && product.children?.[0]?.gallery?.[0]?.full) ||
            product.gallery?.[0]?.full ||
            '',
          description: sanitizeHtml(activeProduct?.contentData?.short_description, {
            allowedTags: ['']
          }).replace(/&amp;/g, '&'),
          url: currentUrl,
          offers: {
            '@type': 'Offer',
            priceCurrency: `${storeConfiguration?.currency?.options?.default}`,
            price: product.price ? `${parseFloat(product.price.special || product.price.regular)}` : 0,
            availability: `${availability}`,
            itemCondition: 'https://schema.org/NewCondition'
          }
        };

        if (product.ean) {
          data.gtin13 = `${product.ean}`;
        }

        // brand
        if (customAttributes.cosBrand) {
          data.brand = {
            '@type': 'Brand',
            name: `${customAttributes.cosBrand}`
          };
        }

        if (product.reviewsSummary?.totalCount > 0) {
          data.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: product.reviewsSummary.averageRating,
            reviewCount: product.reviewsSummary.totalCount
          };
        }

        return JSON.stringify(data);
      }
    };

    return (
      <BackendConfigQuery>
        {({
          data: {
            backendConfig: {
              shop: { activeStore }
            }
          }
        }) => {
          return (
            <Helmet>
              <script className="structured-data-list" type="application/ld+json">
                {structuredDataSingle(activeStore)}
              </script>
            </Helmet>
          );
        }}
      </BackendConfigQuery>
    );
  }

  return null;
});
