import React, { useState } from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { useKeenSlider } from 'keen-slider/react';
import Container from '../../../../layout/Container/Container';
import styles from '../CmsModules.module.scss';
import { LazyLoad } from '../../../../helpers/LazyLoad/LazyLoad';
import svgSprite from '../../../../../../../assets/images/sprites/sprites.svg';
import moduleStyles from './CmsQuote.module.scss';
import { CmsRowHeadline } from './CmsRowHeadline';

const CmsQuote = ({ item }) => {
  const fontClasses = item.font_color === false ? [styles.FontColorSecondary] : [];
  const wrapperBgColor = item.background_color ? item.background_color : '#ffffff';

  const AdaptiveHeight = slider => {
    function updateHeight() {
      slider.container.style.height = `${slider.slides[slider.track.details.rel].height}px`;
    }
    slider.on('created', updateHeight);
    slider.on('slideChanged', updateHeight);
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slides: { perView: 1 },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      }
    },
    [AdaptiveHeight]
  );

  return (
    <section style={{ backgroundColor: `${wrapperBgColor}` }}>
      <LazyLoad offset={2000}>
        <Container className={fontClasses.join(' ')}>
          <CmsRowHeadline item={item} bgColor={wrapperBgColor} />
          <div className={[styles.twoColumnRow, moduleStyles.CmsQuoteColumn, 'HideMobile'].join(' ')}>
            {item?.quote_container?.map((quoteItem, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`quoteItem-'${index}'`} className={moduleStyles.CmsQuote}>
                {item.quote_type === true ? (
                  <img
                    src={`${svgSprite}#stars`}
                    className={moduleStyles.CmsQuoteStars}
                    width="80"
                    height="16"
                    alt="stars"
                  />
                ) : null}
                <InnerHTML html={quoteItem.fields.quote} />
                <small>{quoteItem.fields.source}</small>
              </div>
            ))}
          </div>
          <div className={[moduleStyles.CmsQuoteSlide, 'ShowMobile'].join(' ')}>
            <div className="navigation-wrapper">
              <div ref={sliderRef} className="keen-slider">
                {item?.quote_container?.map((quoteItem, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="keen-slider__slide" key={`quoteItem-${index}`}>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <div key={`quoteItem-'${index}'`} className={moduleStyles.CmsQuote}>
                      {item.quote_type === true ? (
                        <img
                          src={`${svgSprite}#stars`}
                          className={moduleStyles.CmsQuoteStars}
                          width="80"
                          height="16"
                          alt="stars"
                        />
                      ) : null}
                      <InnerHTML html={quoteItem.fields.quote} />
                      <small>{quoteItem.fields.source}</small>
                    </div>
                  </div>
                ))}
              </div>
              {loaded && instanceRef.current && (
                <div className="dots">
                  {[
                    ...Array(
                      instanceRef.current.track.details.slides.length - instanceRef.current.options.slides.perView + 1 >
                        1
                        ? instanceRef.current.track.details.slides.length -
                            instanceRef.current.options.slides.perView +
                            1
                        : 0
                    ).keys()
                  ].map(idx => {
                    return (
                      // eslint-disable-next-line react/button-has-type
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRef.current?.moveToIdx(idx);
                        }}
                        className={`dot${currentSlide === idx ? ' active' : ''}`}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </Container>
      </LazyLoad>
    </section>
  );
};

export default CmsQuote;
