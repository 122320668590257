import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import { InnerHTML } from '@deity/falcon-ui-kit';
import svgSprite from '../../../../../../assets/images/sprites/generalSprite.svg';
import useCheckBreakpoints from '../../../hooks/CheckBreakpoint';
import styles from './PayBackInfo.module.scss';

const PayBackInfo = ({ points }) => {
  const { isMobile } = useCheckBreakpoints();
  return (
    <I18n>
      {t => (
        <>
          <div className="Divider DividerHorizontal" />
          <div className={styles.PaybackInfoWrapper}>
            <a href="/payback" target="_blank" rel="noopener noreferrer nofollow">
              <img
                src={`${svgSprite}${isMobile ? '#paybackLogoMobile' : '#paybackLogo'}`}
                alt="payback"
                width={isMobile ? '35' : '89'}
                height="30"
              />
            </a>
            <InnerHTML
              className={styles.PaybackInfoTxt}
              html={t('payback.pdpPaybackInfo', {
                paybackPoint: points || 0
              })}
            />
          </div>
        </>
      )}
    </I18n>
  );
};

export default PayBackInfo;
